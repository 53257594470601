// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MessagePopup {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#MessagePopup-Section {
  background-color: white;

  border: 2px solid var(--line-color);
  border-radius: 10px;
}

#MessagePopup-Section #MessagePopup-Title {
  text-align: center;
  margin: 10px 10px 0px 10px;
  max-width: 500px;
  font-weight: bold;
  color: var(--primary-color-dark-hover);
  font-size: 20px;
}

#MessagePopup-Section #MessagePopup-Text {
  text-align: center;
  margin: 10px 10px 0px 10px;
  max-width: 500px;
}

#MessagePopup-Section #MessagePopup-Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#MessagePopup-Section #MessagePopup-Buttons #ButtonList-ButtonHolder {
  margin: 10px 0px 0px 10px;
}

#MessagePopup-Section #MessagePopup-ProgressBar {
  text-align: center;
  margin: 10px 10px 0px 10px;
  max-width: 500px;
}
`, "",{"version":3,"sources":["webpack://./../shared/components/MessagePopup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,WAAW;EACX,oCAAoC;;EAEpC,UAAU;;EAEV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;;EAEvB,mCAAmC;EAInC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;EAChB,iBAAiB;EACjB,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":["#MessagePopup {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  height: 100%;\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n\n  z-index: 2;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n#MessagePopup-Section {\n  background-color: white;\n\n  border: 2px solid var(--line-color);\n\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  border-radius: 10px;\n}\n\n#MessagePopup-Section #MessagePopup-Title {\n  text-align: center;\n  margin: 10px 10px 0px 10px;\n  max-width: 500px;\n  font-weight: bold;\n  color: var(--primary-color-dark-hover);\n  font-size: 20px;\n}\n\n#MessagePopup-Section #MessagePopup-Text {\n  text-align: center;\n  margin: 10px 10px 0px 10px;\n  max-width: 500px;\n}\n\n#MessagePopup-Section #MessagePopup-Buttons {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n#MessagePopup-Section #MessagePopup-Buttons #ButtonList-ButtonHolder {\n  margin: 10px 0px 0px 10px;\n}\n\n#MessagePopup-Section #MessagePopup-ProgressBar {\n  text-align: center;\n  margin: 10px 10px 0px 10px;\n  max-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
