// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button {
  margin-top: 10px;
  position: relative;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  color: white;
  background-color: var(--primary-color-dark);
  padding: 0px 15px;
  text-align: center;
  border-radius: 10px;
  transition: background-color 250ms ease-out;

  cursor: pointer;
  overflow: hidden;
}

.Button:hover {
  background-color: var(--primary-color-dark-hover);
}

.Button.disabled {
  background-color: var(--text-color);
  cursor: default;
}

.Button.noMargin {
  margin-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./../shared/components/Button.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;EACZ,2CAA2C;EAC3C,iBAAiB;EACjB,kBAAkB;EAIlB,mBAAmB;EAKnB,2CAA2C;;EAE3C,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,mCAAmC;EACnC,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".Button {\n  margin-top: 10px;\n  position: relative;\n  height: 30px;\n  line-height: 30px;\n  display: inline-block;\n  color: white;\n  background-color: var(--primary-color-dark);\n  padding: 0px 15px;\n  text-align: center;\n\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  border-radius: 10px;\n\n  -webkit-transition: background-color 250ms ease-out;\n  -moz-transition: background-color 250ms ease-out;\n  -o-transition: background-color 250ms ease-out;\n  transition: background-color 250ms ease-out;\n\n  cursor: pointer;\n  overflow: hidden;\n}\n\n.Button:hover {\n  background-color: var(--primary-color-dark-hover);\n}\n\n.Button.disabled {\n  background-color: var(--text-color);\n  cursor: default;\n}\n\n.Button.noMargin {\n  margin-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
