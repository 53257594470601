// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section {
  position: relative;
  padding: 12px;
  border: 2px solid var(--line-color);
  min-width: 500px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;

  margin-bottom: 12px;

  overflow: hidden;
}

.Section-DisabledOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
}

.Section-DisabledOverlay.disabled {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Section.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mCAAmC;EACnC,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAIlB,mBAAmB;;EAEnB,mBAAmB;;EAEnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,WAAW;EACX,0CAA0C;EAC1C,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".Section {\n  position: relative;\n  padding: 12px;\n  border: 2px solid var(--line-color);\n  min-width: 500px;\n  width: fit-content;\n\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  border-radius: 10px;\n\n  margin-bottom: 12px;\n\n  overflow: hidden;\n}\n\n.Section-DisabledOverlay {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  height: 100%;\n  width: 100%;\n  background-color: rgba(255, 255, 255, 0.6);\n  display: none;\n}\n\n.Section-DisabledOverlay.disabled {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
