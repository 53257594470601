/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.11.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `authorization_code` - authorization_code
 * * `refresh_token` - refresh_token
 * @export
 */
export const GrantTypeEnum = {
    AuthorizationCode: 'authorization_code',
    RefreshToken: 'refresh_token'
} as const;
export type GrantTypeEnum = typeof GrantTypeEnum[keyof typeof GrantTypeEnum];


export function GrantTypeEnumFromJSON(json: any): GrantTypeEnum {
    return GrantTypeEnumFromJSONTyped(json, false);
}

export function GrantTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrantTypeEnum {
    return json as GrantTypeEnum;
}

export function GrantTypeEnumToJSON(value?: GrantTypeEnum | null): any {
    return value as any;
}

