// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#GrexManagerLogo-Container {
    position: absolute;
    height: 40px;
    width: 158px;
    top: 20px;
    left: 23px;
}

#GrexManagerLogo-Container svg {
    position: absolute;
    height: 100%;
    width: 100%;
}

#GrexManagerLogo-Container #GrexManagerLogo-Text {
    opacity: 1;
    transition: opacity 200ms ease-out;
}`, "",{"version":3,"sources":["webpack://./../shared/components/GrexManagerLogo.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,UAAU;IAIV,kCAAkC;AACtC","sourcesContent":["#GrexManagerLogo-Container {\n    position: absolute;\n    height: 40px;\n    width: 158px;\n    top: 20px;\n    left: 23px;\n}\n\n#GrexManagerLogo-Container svg {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n\n#GrexManagerLogo-Container #GrexManagerLogo-Text {\n    opacity: 1;\n    -webkit-transition: opacity 200ms ease-out;\n    -moz-transition: opacity 200ms ease-out;\n    -o-transition: opacity 200ms ease-out;\n    transition: opacity 200ms ease-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
