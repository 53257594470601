// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BiLinksScreen {
  width: -moz-fit-content;
  width: fit-content;
}

/* .BiLinksScreen-ErrorMessage {
    margin: -2px 0px 10px 4px;
    color: var(--error-text-color);
} */

.BiLinksScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.BiLinksScreen-Table td,
.BiLinksScreen-Table th {
  padding: 0px;
  /* min-width: 200px; */
  white-space: nowrap;
}

.BiLinksScreen-Table tr {
  height: 30px;
}

.BiLinksScreen-Subtable {
  border-collapse: separate;
  border-spacing: 12px 0px;
  margin: 0px -12px 0px -12px;
}

.BiLinksScreen-ScrollableText {
  display: block;
  margin: 0 auto;
  max-width: 250px;
  overflow-x: auto;
}

.BiLinksScreen-Button {
  cursor: pointer;
}

.BiLinksScreen-Table .Button {
  display: block;
  margin: 0px;
  width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/report/BiLinksScreen.css"],"names":[],"mappings":"AAAA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;;;GAGG;;AAEH;EACE,yBAAyB;EACzB,wBAAwB;EACxB,4BAA4B;EAC5B,wBAAwB;AAC1B;;AAEA;;EAEE,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd","sourcesContent":["#BiLinksScreen {\n  width: fit-content;\n}\n\n/* .BiLinksScreen-ErrorMessage {\n    margin: -2px 0px 10px 4px;\n    color: var(--error-text-color);\n} */\n\n.BiLinksScreen-Table {\n  border-collapse: separate;\n  border-spacing: 12px 8px;\n  margin: -8px -12px -8px -8px;\n  color: var(--text-color);\n}\n\n.BiLinksScreen-Table td,\n.BiLinksScreen-Table th {\n  padding: 0px;\n  /* min-width: 200px; */\n  white-space: nowrap;\n}\n\n.BiLinksScreen-Table tr {\n  height: 30px;\n}\n\n.BiLinksScreen-Subtable {\n  border-collapse: separate;\n  border-spacing: 12px 0px;\n  margin: 0px -12px 0px -12px;\n}\n\n.BiLinksScreen-ScrollableText {\n  display: block;\n  margin: 0 auto;\n  max-width: 250px;\n  overflow-x: auto;\n}\n\n.BiLinksScreen-Button {\n  cursor: pointer;\n}\n\n.BiLinksScreen-Table .Button {\n  display: block;\n  margin: 0px;\n  width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
