// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextInput-Background {
  position: relative;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;

  padding: 0px 15px;
  background-color: var(--primary-color-light);
  color: rgb(0, 0, 0);
  font-size: 17px;
  /* font-family: OpenSans; */
  overflow: hidden;
}

.TextInput-Background.TextInput-BackgroundFocused {
  background-color: var(--primary-color-ultra-light);
}

.TextInput-Input {
  position: absolute;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  height: 100%;
  width: calc(100% - 30px); /* 30px = 2 * 15px(padding) */
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--primary-color-dark);
  font-size: 17px;
  /* font-family: OpenSans; */
}

.TextInput-Input::-moz-placeholder {
  color: var(--text-color);
  opacity: 1; /* Firefox */
}

.TextInput-Input::placeholder {
  color: var(--text-color);
  opacity: 1; /* Firefox */
}

.TextInput-Input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--text-color);
}
`, "",{"version":3,"sources":["webpack://./../shared/components/TextInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EAIjB,mBAAmB;;EAEnB,iBAAiB;EACjB,4CAA4C;EAC5C,mBAAmB;EACnB,eAAe;EACf,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,qBAAgB;OAAhB,gBAAgB;EAChB,wBAAwB;EACxB,YAAY;EACZ,wBAAwB,EAAE,6BAA6B;EACvD,YAAY;EACZ,WAAW;EACX,6BAA6B;EAC7B,aAAa;EACb,YAAY;EACZ,gCAAgC;EAChC,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,UAAU,EAAE,YAAY;AAC1B;;AAHA;EACE,wBAAwB;EACxB,UAAU,EAAE,YAAY;AAC1B;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B","sourcesContent":[".TextInput-Background {\n  position: relative;\n  height: 30px;\n  line-height: 30px;\n\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  border-radius: 10px;\n\n  padding: 0px 15px;\n  background-color: var(--primary-color-light);\n  color: rgb(0, 0, 0);\n  font-size: 17px;\n  /* font-family: OpenSans; */\n  overflow: hidden;\n}\n\n.TextInput-Background.TextInput-BackgroundFocused {\n  background-color: var(--primary-color-ultra-light);\n}\n\n.TextInput-Input {\n  position: absolute;\n  appearance: none;\n  -webkit-appearance: none;\n  height: 100%;\n  width: calc(100% - 30px); /* 30px = 2 * 15px(padding) */\n  padding: 0px;\n  margin: 0px;\n  background-color: transparent;\n  outline: none;\n  border: none;\n  color: var(--primary-color-dark);\n  font-size: 17px;\n  /* font-family: OpenSans; */\n}\n\n.TextInput-Input::placeholder {\n  color: var(--text-color);\n  opacity: 1; /* Firefox */\n}\n\n.TextInput-Input::-ms-input-placeholder {\n  /* Edge 12 -18 */\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
