// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#LoadingOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    z-index: 2;
    
    background-color: rgba(255,255,255,0.9);
}`, "",{"version":3,"sources":["webpack://./../shared/components/LoadingOverlay.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,WAAW;;IAEX,UAAU;;IAEV,uCAAuC;AAC3C","sourcesContent":["#LoadingOverlay {\n    position: fixed;\n    top: 0px;\n    left: 0px;\n    height: 100%;\n    width: 100%;\n\n    z-index: 2;\n    \n    background-color: rgba(255,255,255,0.9);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
