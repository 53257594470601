// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileInput {
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    
    background-color: var(--primary-color-light);
    color: var(--primary-color-dark);
    border-radius: 10px;

    padding: 4px 5px;
    width: 300px;
    cursor: pointer;
};`, "",{"version":3,"sources":["webpack://./../shared/components/FileInput.css"],"names":[],"mappings":"AAAA;IACI,qBAAgB;SAAhB,gBAAgB;IAChB,wBAAwB;;IAExB,4CAA4C;IAC5C,gCAAgC;IAIhC,mBAAmB;;IAEnB,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB,CAAA","sourcesContent":[".fileInput {\n    appearance: none;\n    -webkit-appearance: none;\n    \n    background-color: var(--primary-color-light);\n    color: var(--primary-color-dark);\n\n    -webkit-border-radius: 10px;\n    -moz-border-radius: 10px;\n    border-radius: 10px;\n\n    padding: 4px 5px;\n    width: 300px;\n    cursor: pointer;\n};"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
