// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#NoneScreen-Container {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color-ultra-light);

    -webkit-user-select: none;

            user-select: none;
}

#NoneScreen-Section {
    min-width: 460px;
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 2px solid var(--line-color);
}

#NoneScreen-TextHolder {
    width: 400px;
}

#NoneScreen-Text {
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/screens/auth/NoneScreen.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,WAAW;IACX,kDAAkD;;IAElD,yBAAiB;;YAAjB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["#NoneScreen-Container {\n    position: fixed;\n    top: 0px;\n    left: 0px;\n    height: 100%;\n    width: 100%;\n    background-color: var(--primary-color-ultra-light);\n\n    user-select: none;\n}\n\n#NoneScreen-Section {\n    min-width: 460px;\n    width: 50%;\n    height: 100%;\n    background-color: white;\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    border-right: 2px solid var(--line-color);\n}\n\n#NoneScreen-TextHolder {\n    width: 400px;\n}\n\n#NoneScreen-Text {\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
