// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.TextButton p {
  position: relative;
  height: 30px;
  line-height: 30px;
  color: var(--primary-color-dark);
  padding: 0px 15px;
  text-align: center;
  cursor: pointer;
}

.TextButton p:before {
  position: absolute;
  margin: 0 auto;
  top: 100%;
  left: 0;
}

.TextButton p:hover:before {
  width: 100%;
  height: 2px;
  background-color: #000;
  content: "";
  opacity: 0.2;

  transition-duration: 0.3s;
}
`, "",{"version":3,"sources":["webpack://./../shared/components/TextButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,gCAAgC;EAChC,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,SAAS;EACT,OAAO;AACT;;AAEA;EACE,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,YAAY;;EAEZ,yBAAyB;AAC3B","sourcesContent":[".TextButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 10px;\n}\n\n.TextButton p {\n  position: relative;\n  height: 30px;\n  line-height: 30px;\n  color: var(--primary-color-dark);\n  padding: 0px 15px;\n  text-align: center;\n  cursor: pointer;\n}\n\n.TextButton p:before {\n  position: absolute;\n  margin: 0 auto;\n  top: 100%;\n  left: 0;\n}\n\n.TextButton p:hover:before {\n  width: 100%;\n  height: 2px;\n  background-color: #000;\n  content: \"\";\n  opacity: 0.2;\n\n  transition-duration: 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
