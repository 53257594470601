// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#NewVersionModelScreen {
    width: -moz-fit-content;
    width: fit-content;
}

.NewVersionModelScreen-ErrorMessage {
    margin: -2px 0px 10px 4px;
    color: var(--error-text-color);
}

.NewVersionModelScreen-Table {
    border-collapse: separate;
    border-spacing: 12px 8px;
    margin: -8px -12px -8px -8px;
    color: var(--text-color);
}

.NewVersionModelScreen-Table td, .NewVersionModelScreen-Table th {
    padding: 0px;
    min-width: 200px;
}

.NewVersionModelScreen-Table .TextInput-Background {
    width: 300px;
}

.NewVersionModelScreen-Table #DropDown {
    width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/screens/project/NewVersionModelScreen.css"],"names":[],"mappings":"AAAA;IACI,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["#NewVersionModelScreen {\n    width: fit-content;\n}\n\n.NewVersionModelScreen-ErrorMessage {\n    margin: -2px 0px 10px 4px;\n    color: var(--error-text-color);\n}\n\n.NewVersionModelScreen-Table {\n    border-collapse: separate;\n    border-spacing: 12px 8px;\n    margin: -8px -12px -8px -8px;\n    color: var(--text-color);\n}\n\n.NewVersionModelScreen-Table td, .NewVersionModelScreen-Table th {\n    padding: 0px;\n    min-width: 200px;\n}\n\n.NewVersionModelScreen-Table .TextInput-Background {\n    width: 300px;\n}\n\n.NewVersionModelScreen-Table #DropDown {\n    width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
