// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#VersionRegionMapScreen {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.VersionRegionMapScreen-MapViewerHolder {
  flex-grow: 1;

  border: 2px solid var(--line-color);
  border-radius: 10px;

  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/screens/mapviewer/VersionRegionMapScreen.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;;EAEZ,mCAAmC;EAInC,mBAAmB;;EAEnB,gBAAgB;AAClB","sourcesContent":["#VersionRegionMapScreen {\n  height: 100%;\n\n  display: flex;\n  flex-direction: column;\n}\n\n.VersionRegionMapScreen-MapViewerHolder {\n  flex-grow: 1;\n\n  border: 2px solid var(--line-color);\n\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  border-radius: 10px;\n\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
