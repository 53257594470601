// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ButtonList-ButtonHolder {
  position: relative;
  margin: 0px 0px -8px 0px;
}

.ButtonList-Button {
  position: relative;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  color: white;
  background-color: var(--primary-color-dark);
  padding: 0px 15px;
  margin: 0px 8px 8px 0px;
  border-radius: 10px;
  transition: background-color 250ms ease-out;

  cursor: pointer;
}

.ButtonList-Button:hover {
  background-color: var(--primary-color-dark-hover);
}

.ButtonList-Button.disabled {
  background-color: var(--text-color);
  cursor: default;
}

.ButtonList-Button.warning {
  background-color: var(--error-text-color);
}
`, "",{"version":3,"sources":["webpack://./../shared/components/ButtonList.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;EACZ,2CAA2C;EAC3C,iBAAiB;EACjB,uBAAuB;EAIvB,mBAAmB;EAKnB,2CAA2C;;EAE3C,eAAe;AACjB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,mCAAmC;EACnC,eAAe;AACjB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":["#ButtonList-ButtonHolder {\n  position: relative;\n  margin: 0px 0px -8px 0px;\n}\n\n.ButtonList-Button {\n  position: relative;\n  height: 30px;\n  line-height: 30px;\n  display: inline-block;\n  color: white;\n  background-color: var(--primary-color-dark);\n  padding: 0px 15px;\n  margin: 0px 8px 8px 0px;\n\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  border-radius: 10px;\n\n  -webkit-transition: background-color 250ms ease-out;\n  -moz-transition: background-color 250ms ease-out;\n  -o-transition: background-color 250ms ease-out;\n  transition: background-color 250ms ease-out;\n\n  cursor: pointer;\n}\n\n.ButtonList-Button:hover {\n  background-color: var(--primary-color-dark-hover);\n}\n\n.ButtonList-Button.disabled {\n  background-color: var(--text-color);\n  cursor: default;\n}\n\n.ButtonList-Button.warning {\n  background-color: var(--error-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
