/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Session,
} from '../models';
import {
    SessionFromJSON,
    SessionToJSON,
} from '../models';

/**
 * 
 */
export class ExcelServicesApi extends runtime.BaseAPI {

    /**
     * Save an uploaded Excel model
     */
    async apiGrexexcelservicesGrexSaveModelCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexexcelservices/grex/SaveModel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save an uploaded Excel model
     */
    async apiGrexexcelservicesGrexSaveModelCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexexcelservicesGrexSaveModelCreateRaw(initOverrides);
    }

    /**
     * Test the status of the Excel service
     */
    async apiGrexexcelservicesGrexTestServiceRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexexcelservices/grex/TestService`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test the status of the Excel service
     */
    async apiGrexexcelservicesGrexTestServiceRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexexcelservicesGrexTestServiceRetrieveRaw(initOverrides);
    }

    /**
     * End a session
     */
    async apiGrexexcelservicesSessionEndSessionRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexexcelservices/session/EndSession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * End a session
     */
    async apiGrexexcelservicesSessionEndSessionRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.apiGrexexcelservicesSessionEndSessionRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Refresh a session
     */
    async apiGrexexcelservicesSessionRefreshSessionRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexexcelservices/session/RefreshSession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * Refresh a session
     */
    async apiGrexexcelservicesSessionRefreshSessionRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.apiGrexexcelservicesSessionRefreshSessionRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Start a new session
     */
    async apiGrexexcelservicesSessionStartSessionRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexexcelservices/session/StartSession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * Start a new session
     */
    async apiGrexexcelservicesSessionStartSessionRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.apiGrexexcelservicesSessionStartSessionRetrieveRaw(initOverrides);
        return await response.value();
    }

}
