// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ProjectInfoScreen {
  width: -moz-fit-content;
  width: fit-content;
}

.ProjectInfoScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.ProjectInfoScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.ProjectInfoScreen-Table td,
.ProjectInfoScreen-Table th {
  padding: 0px;
  min-width: 200px;
}

.ProjectInfoScreen-Table .TextInput-Background {
  width: 300px;
}

.ProjectInfoScreen-Table #DropDown {
  width: 300px;
}

.ProjectInfoScreen-Table .ProjectInfoScreen-CellWidth {
  width: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/project/ProjectInfoScreen.css"],"names":[],"mappings":"AAAA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,4BAA4B;EAC5B,wBAAwB;AAC1B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["#ProjectInfoScreen {\n  width: fit-content;\n}\n\n.ProjectInfoScreen-ErrorMessage {\n  margin: -2px 0px 10px 4px;\n  color: var(--error-text-color);\n}\n\n.ProjectInfoScreen-Table {\n  border-collapse: separate;\n  border-spacing: 12px 8px;\n  margin: -8px -12px -8px -8px;\n  color: var(--text-color);\n}\n\n.ProjectInfoScreen-Table td,\n.ProjectInfoScreen-Table th {\n  padding: 0px;\n  min-width: 200px;\n}\n\n.ProjectInfoScreen-Table .TextInput-Background {\n  width: 300px;\n}\n\n.ProjectInfoScreen-Table #DropDown {\n  width: 300px;\n}\n\n.ProjectInfoScreen-Table .ProjectInfoScreen-CellWidth {\n  width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
