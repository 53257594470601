// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#LogScreen {
    height: 100%;

    display: flex;
	flex-direction: column;
}

#LogScreen-ScrollView {
    position: relative;
    flex-grow: 1;
    width: 100%;

    border: 2px solid var(--line-color);
    border-radius: 10px;

    overflow: auto;
}

#LogScreen-ScrollHolder {
    position: absolute;
    top: 0px;
    left: 0px;
}

#LogScreen-Table th {
    min-width: 150px;
    padding: 10px 25px;
    color: var(--text-color);
}

#LogScreen-Table td {
    min-width: 150px;
    padding: 5px 25px;
    color: var(--primary-color-dark);
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/screens/log/LogScreen.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,aAAa;CAChB,sBAAsB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;;IAEX,mCAAmC;IAInC,mBAAmB;;IAEnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gCAAgC;IAChC,mBAAmB;AACvB","sourcesContent":["#LogScreen {\n    height: 100%;\n\n    display: flex;\n\tflex-direction: column;\n}\n\n#LogScreen-ScrollView {\n    position: relative;\n    flex-grow: 1;\n    width: 100%;\n\n    border: 2px solid var(--line-color);\n\n    -webkit-border-radius: 10px;\n    -moz-border-radius: 10px;\n    border-radius: 10px;\n\n    overflow: auto;\n}\n\n#LogScreen-ScrollHolder {\n    position: absolute;\n    top: 0px;\n    left: 0px;\n}\n\n#LogScreen-Table th {\n    min-width: 150px;\n    padding: 10px 25px;\n    color: var(--text-color);\n}\n\n#LogScreen-Table td {\n    min-width: 150px;\n    padding: 5px 25px;\n    color: var(--primary-color-dark);\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
