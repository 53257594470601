// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#DropDown {
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--primary-color-dark);
    width: 100%;
    height: 30px;
    padding: 0px 15px;
    border-radius: 10px;

    cursor: pointer;
};`, "",{"version":3,"sources":["webpack://./../shared/components/DropDown.css"],"names":[],"mappings":"AAAA;IACI,qBAAgB;SAAhB,gBAAgB;IAChB,wBAAwB;;IAExB,aAAa;IACb,YAAY;IACZ,4CAA4C;IAC5C,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,iBAAiB;IAIjB,mBAAmB;;IAEnB,eAAe;AACnB,CAAA","sourcesContent":["#DropDown {\n    appearance: none;\n    -webkit-appearance: none;\n    \n    outline: none;\n    border: none;\n    background-color: var(--primary-color-light);\n    color: var(--primary-color-dark);\n    width: 100%;\n    height: 30px;\n    padding: 0px 15px;\n\n    -webkit-border-radius: 10px;\n    -moz-border-radius: 10px;\n    border-radius: 10px;\n\n    cursor: pointer;\n};"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
