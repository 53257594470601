// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#VersionModelInfoScreen {
  width: -moz-fit-content;
  width: fit-content;
}

.VersionModelInfoScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.VersionModelInfoScreen-Table {
  border-collapse: separate;
  border-spacing: 12px 8px;
  margin: -8px -12px -8px -8px;
  color: var(--text-color);
}

.VersionModelInfoScreen-Table td,
.VersionModelInfoScreen-Table th {
  padding: 0px;
}

.VersionModelInfoScreen-Table .TextInput-Background {
  width: 300px;
}

.VersionModelInfoScreen-Table #DropDown {
  width: 300px;
}

.VersionModelInfoScreen-Table a {
  color: var(--text-color);
}

.VersionModelInfoScreen-Table span {
  color: var(--text-color);
  cursor: pointer;
  text-decoration: underline;
}

.VersionModelInfoScreen-DeleteTd {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/screens/project/VersionModelInfoScreen.css"],"names":[],"mappings":"AAAA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,4BAA4B;EAC5B,wBAAwB;AAC1B;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB","sourcesContent":["#VersionModelInfoScreen {\n  width: fit-content;\n}\n\n.VersionModelInfoScreen-ErrorMessage {\n  margin: -2px 0px 10px 4px;\n  color: var(--error-text-color);\n}\n\n.VersionModelInfoScreen-Table {\n  border-collapse: separate;\n  border-spacing: 12px 8px;\n  margin: -8px -12px -8px -8px;\n  color: var(--text-color);\n}\n\n.VersionModelInfoScreen-Table td,\n.VersionModelInfoScreen-Table th {\n  padding: 0px;\n}\n\n.VersionModelInfoScreen-Table .TextInput-Background {\n  width: 300px;\n}\n\n.VersionModelInfoScreen-Table #DropDown {\n  width: 300px;\n}\n\n.VersionModelInfoScreen-Table a {\n  color: var(--text-color);\n}\n\n.VersionModelInfoScreen-Table span {\n  color: var(--text-color);\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n.VersionModelInfoScreen-DeleteTd {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
