// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoMessage {
  display: flex;
  align-items: center;
  padding: 10px 15px 0px 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #14366e;
  background-color: #e9f4fa;
  border-color: #adc4eb;
  max-width: 500px;
}

.ErrorMessage {
  display: flex;
  align-items: center;
  padding: 10px 15px 0px 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  max-width: 500px;
}
`, "",{"version":3,"sources":["webpack://./../shared/components/Alert.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;;EAElB,cAAc;EACd,yBAAyB;EACzB,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".InfoMessage {\n  display: flex;\n  align-items: center;\n  padding: 10px 15px 0px 15px;\n  margin-bottom: 20px;\n  border: 1px solid transparent;\n  border-radius: 4px;\n  color: #14366e;\n  background-color: #e9f4fa;\n  border-color: #adc4eb;\n  max-width: 500px;\n}\n\n.ErrorMessage {\n  display: flex;\n  align-items: center;\n  padding: 10px 15px 0px 15px;\n  margin-bottom: 20px;\n  border: 1px solid transparent;\n  border-radius: 4px;\n\n  color: #721c24;\n  background-color: #f8d7da;\n  border-color: #f5c6cb;\n  max-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
