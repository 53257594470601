// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the progress bar */
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  height: 20px;
  overflow: hidden;
}

/* Fill for the progress bar */
.progress-bar-fill {
  height: 100%;
  transition: width 0.3s ease-in-out;
  background-color: var(--primary-color-dark-hover);
}

.progress-bar-fill {
  height: 100%;
  transition: width 0.3s ease-in-out;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA,8BAA8B;AAC9B;EACE,YAAY;EACZ,kCAAkC;EAClC,iDAAiD;AACnD;;AAEA;EACE,YAAY;EACZ,kCAAkC;EAClC,kBAAkB;AACpB","sourcesContent":["/* Container for the progress bar */\n.progress-bar-container {\n  width: 100%;\n  background-color: #e0e0e0;\n  border-radius: 8px;\n  height: 20px;\n  overflow: hidden;\n}\n\n/* Fill for the progress bar */\n.progress-bar-fill {\n  height: 100%;\n  transition: width 0.3s ease-in-out;\n  background-color: var(--primary-color-dark-hover);\n}\n\n.progress-bar-fill {\n  height: 100%;\n  transition: width 0.3s ease-in-out;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
