// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ResetPasswordMfaScreen-Container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-ultra-light);

  -webkit-user-select: none;

          user-select: none;
}

#ResetPasswordMfaScreen-Section {
  min-width: 400px;
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-right: 2px solid var(--line-color);
}

#ResetPasswordMfaScreen-ResetPasswordMfaHolder {
  width: 320px;
  margin-bottom: 120px;
}

#ResetPasswordMfaScreen-ResetPasswordMfaHolder .label {
  margin-top: 10px;
}

#ResetPasswordMfaScreen-ResetPasswordMfaHolder .Button {
  width: 100%;
  margin-top: 15px;
}


.errorMessage {
  display: flex;
  align-items: center;
  padding: 10px 15px 0px 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}`, "",{"version":3,"sources":["webpack://./src/screens/auth/ResetPasswordMfaScreen.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,WAAW;EACX,kDAAkD;;EAElD,yBAAiB;;UAAjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;;EAElB,cAAc;EACd,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":["#ResetPasswordMfaScreen-Container {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  height: 100%;\n  width: 100%;\n  background-color: var(--primary-color-ultra-light);\n\n  user-select: none;\n}\n\n#ResetPasswordMfaScreen-Section {\n  min-width: 400px;\n  width: 50%;\n  height: 100%;\n  background-color: white;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  border-right: 2px solid var(--line-color);\n}\n\n#ResetPasswordMfaScreen-ResetPasswordMfaHolder {\n  width: 320px;\n  margin-bottom: 120px;\n}\n\n#ResetPasswordMfaScreen-ResetPasswordMfaHolder .label {\n  margin-top: 10px;\n}\n\n#ResetPasswordMfaScreen-ResetPasswordMfaHolder .Button {\n  width: 100%;\n  margin-top: 15px;\n}\n\n\n.errorMessage {\n  display: flex;\n  align-items: center;\n  padding: 10px 15px 0px 15px;\n  margin-bottom: 20px;\n  border: 1px solid transparent;\n  border-radius: 4px;\n\n  color: #721c24;\n  background-color: #f8d7da;\n  border-color: #f5c6cb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
