// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../GrexManagerFrontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckBox-CheckBox {
    position: relative;
    cursor: pointer;
    height: 18px;
    width: 18px;
    border-radius: 6px;

    border: 2px solid var(--primary-color-dark-hover);
}

.CheckBox-CheckBox:hover {
    background-color: var(--primary-color-dark);
}

.CheckBox-Checked {
    background-color: var(--primary-color-dark-hover);
}

.CheckBox-CheckMark {
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./../shared/components/CheckBox.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,WAAW;IAIX,kBAAkB;;IAElB,iDAAiD;AACrD;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,iDAAiD;AACrD;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,wBAAwB;AAC5B","sourcesContent":[".CheckBox-CheckBox {\n    position: relative;\n    cursor: pointer;\n    height: 18px;\n    width: 18px;\n    \n    -webkit-border-radius: 6px;\n    -moz-border-radius: 6px;\n    border-radius: 6px;\n\n    border: 2px solid var(--primary-color-dark-hover);\n}\n\n.CheckBox-CheckBox:hover {\n    background-color: var(--primary-color-dark);\n}\n\n.CheckBox-Checked {\n    background-color: var(--primary-color-dark-hover);\n}\n\n.CheckBox-CheckMark {\n    position: absolute;\n    left: 5px;\n    top: 1px;\n    width: 5px;\n    height: 10px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
